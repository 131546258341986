
import useConfirmService from "@/composables/useConfirmService";
import useToastService from "@/composables/useToastService";
import {
  GetOrganisationsDocument,
  GetOrganisationsQuery,
  OrganizationType,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  GetOrganizationQueryVariables,
  GetOrganizationQuery,
  GetOrganizationDocument,
  RoleType,
  RoleAssigneeType,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client/core";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import gql from "graphql-tag";
import { defineComponent, PropType, reactive, ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import VDButton from "../../VDButton.vue";
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import RolePicker from "./RolePicker.vue";
import Error from "@/components/Error.vue";
import useUser from "@/composables/useUser";
import useAddRoleAssignee from "@/views/Role/mutations/useAddRoleAssignee";
import useRemoveRoleAssignee from "@/views/Role/mutations/useRemoveRoleAssignee";
import { ORGANIZATION_TYPES } from "@/constants";
gql`
  mutation CreateOrganization($input: CreateOrganizationInputType!) {
    createOrganization(input: $input) {
      ...OrganizationParts
    }
  }
`;

gql`
  mutation DeleteOrganization($organizationId: ID!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;

gql`
  mutation UpdateOrganization($input: UpdateOrganizationInputType!) {
    updateOrganization(input: $input) {
      ...OrganizationParts
    }
  }
`;

export default defineComponent({
  components: { VDButton, RolePicker, Error },
  props: {
    organization: {
      type: Object as PropType<OrganizationType | null>,
      default: null,
    },
  },
  data() {
    return {
      options: [...ORGANIZATION_TYPES],
    };
  },
  setup(props) {
    const confirmService = useConfirmService();
    const router = useRouter();
    const toastService = useToastService();
    const isVisible = ref(false);
    const newOrganization = reactive<OrganizationType>({
      name: "",
      cvr: "",
      type: "",
      assigneeRoles: [],
      brokerId: "",
      cooperationType: "",
      externalId: "",
      nearPension: "",
    });
    const v$ = useVuelidate(useValidations(), newOrganization);
    const organizationRolePicker = ref<VDPopupListboxClass>();
    const isNewOrganization = computed(() => !props.organization);

    const hasChanges = computed(() => {
      if (isNewOrganization.value) {
        return (
          newOrganization.name != "" &&
          newOrganization.cvr != "" &&
          newOrganization.type != "" &&
          newOrganization.externalId != "" &&
          newOrganization.brokerId != ""
        );
      } else {
        return (
          newOrganization.name &&
          newOrganization.cvr &&
          newOrganization.type &&
          newOrganization.externalId &&
          newOrganization.brokerId &&
          (newOrganization.name != props.organization?.name ||
            newOrganization.cvr != props.organization?.cvr ||
            newOrganization.type != props.organization?.type ||
            newOrganization.externalId != props.organization?.externalId ||
            newOrganization.brokerId != props.organization?.brokerId ||
            newOrganization.cooperationType !=
              props.organization?.cooperationType ||
            newOrganization.nearPension != props.organization?.nearPension)
        );
      }
    });
    const { isGlobalAdmin, isLocalAdmin } = useUser();

    const addRoleAssigneMutation = useAddRoleAssignee();
    const removeRoleAssigneMutation = useRemoveRoleAssignee();

    const canViewOrganizationRoles = computed<boolean>(
      () => isGlobalAdmin.value || isLocalAdmin.value
    );

    const isEditOrganizationDisabled = computed<boolean>(
      () => !isGlobalAdmin.value && isLocalAdmin.value
    );

    const deleteOrganizationMutation = useDeleteOrganizationMutation({});
    const createOrganizationMutation = useCreateOrganizationMutation({});
    const updateOrganizationMutation = useUpdateOrganizationMutation({});

    function deleteOrganization(event: Event) {
      function callMutation() {
        if (newOrganization.organizationId)
          deleteOrganizationMutation.mutate(
            { organizationId: newOrganization.organizationId },
            {
              refetchQueries: [{ query: GetOrganisationsDocument }],
            }
          );
      }

      confirmService.show(
        event,
        `Er du sikker? Handlingen kan ikke fortrydes`,
        "Ja, slet",
        "Afbryd",
        () => {
          callMutation();
        }
      );
    }

    function primaryButtonPressed() {
      v$.value.$touch();
      if (!v$.value.$invalid)
        if (props.organization && props.organization.organizationId) {
          updateOrganizationMutation.mutate(
            {
              input: {
                organizationId: newOrganization.organizationId,
                name: newOrganization.name,
                cvr: newOrganization.cvr,
                type: newOrganization.type,
                brokerId: newOrganization.brokerId,
                cooperationType: newOrganization.cooperationType,
                externalId: newOrganization.externalId,
                nearPension: newOrganization.nearPension,
              },
            },
            {
              update: (cache, { data: newOrganization }) => {
                const parentQuery: QueryOptions<
                  GetOrganizationQueryVariables,
                  GetOrganizationQuery
                > = {
                  query: GetOrganizationDocument,
                  variables: {
                    organizationId: props.organization?.organizationId,
                    pageParams: { pageSize: 10, currentPage: 1 },
                  },
                };

                const cachedData = cache.readQuery(parentQuery);

                cache.writeQuery({
                  ...parentQuery,
                  data: {
                    organization: {
                      ...cachedData?.organization,
                      name: newOrganization?.updateOrganization?.name,
                      cvr: newOrganization?.updateOrganization?.cvr,
                      type: newOrganization?.updateOrganization?.type,
                      brokerId: newOrganization?.updateOrganization?.brokerId,
                      cooperationType:
                        newOrganization?.updateOrganization?.cooperationType,
                      externalId:
                        newOrganization?.updateOrganization?.externalId,
                      nearPension:
                        newOrganization?.updateOrganization?.nearPension,
                    },
                  },
                });
              },
            }
          );
        } else {
          createOrganizationMutation.mutate(
            {
              input: {
                name: newOrganization.name,
                cvr: newOrganization.cvr,
                type: newOrganization.type,
                brokerId: newOrganization.brokerId,
                cooperationType: newOrganization.cooperationType,
                externalId: newOrganization.externalId,
                nearPension: newOrganization.nearPension,
                roleIds:
                  newOrganization.assigneeRoles?.map((x) => x?.roleId) ?? [],
              },
            },
            {
              update: (cache, { data: newOrganization }) => {
                const parentQuery: QueryOptions<
                  undefined,
                  GetOrganisationsQuery
                > = {
                  query: GetOrganisationsDocument,
                };

                const cachedData = cache.readQuery(parentQuery);
                cache.writeQuery({
                  ...parentQuery,
                  data: {
                    organizations: {
                      ...cachedData?.organizations,
                      count: (cachedData?.organizations?.count ?? 1) + 1,
                      items: [
                        ...(cachedData?.organizations?.items ?? []),
                        newOrganization?.createOrganization ?? null,
                      ],
                    },
                  },
                });
              },
            }
          );
        }
    }

    createOrganizationMutation.onDone((result) => {
      v$.value.$reset();
      Object.assign(newOrganization, { name: "", cvr: "", organizationId: "" });
      isVisible.value = false;

      toastService.show({
        severity: "success",
        title: "Organisation oprettet",
        message: `Den har fået ID: ${result.data?.createOrganization?.organizationId}`,
      });
    });

    updateOrganizationMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Organisationen opdateret",
        message: "Dine ændringer er blevet gemt",
      });
      isVisible.value = false;
    });

    deleteOrganizationMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Organisation slettet",
        message: `Organisationen er blevet slettet`,
      });
      router.push("/organizations");
    });

    function onAddedRole(role: RoleType) {
      function add() {
        newOrganization.assigneeRoles = [
          ...(newOrganization.assigneeRoles ?? []),
          {
            roleId: role.roleId,
            roleName: role.name,
            applicationId: role.application?.applicationId,
            applicationName: role.application?.name,
          },
        ];
      }

      if (!isNewOrganization.value) {
        addRoleAssigneMutation
          .call(newOrganization.organizationId ?? "", role.roleId ?? "")
          .then((value) => {
            if (value != null) add();
          });
      } else {
        add();
      }
    }

    function onRemovedRole(role: RoleType) {
      function remove() {
        newOrganization.assigneeRoles =
          newOrganization.assigneeRoles?.filter(
            (element) => element?.roleId != role.roleId
          ) ?? [];
      }

      if (!isNewOrganization.value) {
        removeRoleAssigneMutation
          .call(newOrganization.organizationId ?? "", role.roleId ?? "")
          .then((value) => {
            if (value != null) remove();
          });
      } else {
        remove();
      }
    }

    watch(
      () => props.organization,
      (newValue) => {
        Object.assign(newOrganization, newValue);
      },
      { immediate: true }
    );

    return {
      isVisible,
      show: () => (isVisible.value = true),
      hide: () => (isVisible.value = false),
      newOrganization,
      v$,
      deleteOrganizationMutation,
      updateOrganizationMutation,
      createOrganizationMutation,
      primaryButtonPressed,
      deleteOrganization,
      organizationRolePicker,
      addRemoveLoading: computed(
        () =>
          addRoleAssigneMutation.loading.value ||
          removeRoleAssigneMutation.loading.value
      ),
      onAddedRole,
      onRemovedRole,
      canViewOrganizationRoles,
      isEditOrganizationDisabled,
      hasChanges,
    };
  },
});

function useValidations() {
  return {
    name: { required },
    cvr: { required },
    externalId: { required },
    brokerId: { required },
    type: { required },
  };
}

export declare class OrganizationModal {
  show(): void;
  hide(): void;
}
